import React from "react";
import theme from "theme";
import { Theme, Text, Section, Button, Image, Box, Em } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override, StackItem, Stack } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				TechFix PC Repair Services
			</title>
			<meta name={"description"} content={"Ваш надійний партнер для всіх потреб у ремонті ПК"} />
			<meta property={"og:title"} content={"TechFix PC Repair Services"} />
			<meta property={"og:description"} content={"Ваш надійний партнер для всіх потреб у ремонті ПК"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/667472d98e2e8e00217d2cbe/images/1-4.jpg?v=2024-06-20T19:30:27.444Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/667472d98e2e8e00217d2cbe/images/1-4.jpg?v=2024-06-20T19:30:27.444Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/667472d98e2e8e00217d2cbe/images/1-4.jpg?v=2024-06-20T19:30:27.444Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/667472d98e2e8e00217d2cbe/images/1-4.jpg?v=2024-06-20T19:30:27.444Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/667472d98e2e8e00217d2cbe/images/1-4.jpg?v=2024-06-20T19:30:27.444Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/667472d98e2e8e00217d2cbe/images/1-4.jpg?v=2024-06-20T19:30:27.444Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/667472d98e2e8e00217d2cbe/images/1-4.jpg?v=2024-06-20T19:30:27.444Z"} />
		</Helmet>
		<Components.Header>
			<Override slot="text" />
			<Override slot="text2" />
			<Override slot="text3" />
			<Override slot="text1" />
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="text4" />
			<Override slot="text5" />
			<Override slot="text6" />
		</Components.Header>
		<Section
			background="linear-gradient(0deg,rgba(4, 8, 12, 0.6) 0%,rgba(4, 8, 12, 0.6) 100%),--color-darkL2 url(https://uploads.quarkly.io/667472d98e2e8e00217d2cbe/images/1-1.jpg?v=2024-06-20T19:30:27.442Z) center center/cover"
			padding="64px 0"
			sm-padding="40px 0"
			color="--light"
			font="--base"
		>
			<Stack>
				<StackItem width="75%" lg-width="100%">
					<Override slot="StackItemContent" flex-direction="column" />
					<Text
						as="h1"
						font="--headline1"
						md-font="--headline2"
						margin="10px 0"
						color="--light"
						border-color="--color-light"
					>
						Ласкаво просимо до TechFix PC Repair Services
					</Text>
				</StackItem>
			</Stack>
		</Section>
		<Section padding="80px 0" sm-padding="40px 0" background="--color-light">
			<Override slot="SectionContent" align-items="center" />
			<Text
				as="h2"
				font="--headline2"
				md-font="--headline2"
				margin="20px 0 0 0"
				text-align="center"
			>
				Ми прагнемо надавати першокласні послуги з ремонту ваших персональних комп’ютерів, гарантуючи, що ви зможете повернутися до повсякденної діяльності з мінімальними перервами.
			</Text>
			<Text as="p" font="--lead" margin="20px 0 20px 0" text-align="center">
				Наша команда кваліфікованих техніків тут, щоб вирішити всі ваші проблеми з ПК з точністю та турботою.
			</Text>
			<Button
				padding="15px 44px 15px 44px"
				background="--color-darkL1"
				border-color="rgba(236, 255, 0, 0.3)"
				box-shadow="5px 5px 3px 0 rgba(219, 204, 27, 0.69)"
				font="normal 300 16px/1.5 --fontFamily-sansTrebuchet"
				type="link"
				text-decoration-line="initial"
				href="/services"
			>
				Наші послуги
			</Button>
		</Section>
		<Section padding="80px 0 90px 0" quarkly-title="Cards-3">
			<Override slot="SectionContent" align-items="center" />
			<Text
				margin="0px 0px 1.5rem 0px"
				font="normal 600 64px/1.2 --fontFamily-sans"
				text-align="center"
				md-margin="0px 0px 40px 0px"
				sm-font="normal 600 42px/1.2 --fontFamily-sans"
			>
				Про TechFix
			</Text>
			<Text margin="0px 0px 2rem 0px" font="normal 300 16px/1.5 --fontFamily-sansTrebuchet" color="#676e75">
				У TechFix ми пишаємося тим, що пропонуємо комплексні послуги з ремонту ПК, які відповідають потребам як окремих осіб, так і компаній. Наш досвід охоплює широкий спектр послуг, від простої заміни апаратного забезпечення до складного усунення несправностей програмного забезпечення. Ми розуміємо, наскільки важливий ваш комп’ютер у вашому повсякденному житті, тому ми прагнемо надавати ефективні та надійні рішення для ремонту.
			</Text>
			<Text margin="0px 0px 2rem 0px" font="normal 300 16px/1.5 --fontFamily-sansTrebuchet" color="#676e75">
				Наша місія полягає в наданні виняткових послуг, які перевершують ваші очікування. Ми використовуємо лише високоякісні запчастини та новітні діагностичні інструменти, щоб забезпечити правильний ремонт вашого ПК з першого разу. Незалежно від того, чи маєте ви справу з повільним комп’ютером, вірусною інфекцією, апаратним збоєм чи будь-якою іншою проблемою, TechFix — це найкраще місце для ремонту ПК.
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="36px 34px"
				margin="0px 0px 60px 0px"
				md-grid-template-columns="1fr"
				md-grid-gap="46px 0"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
					margin="50px 0px 0px 0px"
					md-margin="0 0px 0px 0px"
				>
					<Image
						src="https://uploads.quarkly.io/667472d98e2e8e00217d2cbe/images/2-2.jpg?v=2024-06-20T19:30:27.443Z"
						display="block"
						margin="0px 0px 45px 0px"
						width="100%"
						height="500px"
						object-fit="cover"
						lg-height="400px"
						md-height="600px"
						sm-height="400px"
						srcSet="https://smartuploads.quarkly.io/667472d98e2e8e00217d2cbe/images/2-2.jpg?v=2024-06-20T19%3A30%3A27.443Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/667472d98e2e8e00217d2cbe/images/2-2.jpg?v=2024-06-20T19%3A30%3A27.443Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/667472d98e2e8e00217d2cbe/images/2-2.jpg?v=2024-06-20T19%3A30%3A27.443Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/667472d98e2e8e00217d2cbe/images/2-2.jpg?v=2024-06-20T19%3A30%3A27.443Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/667472d98e2e8e00217d2cbe/images/2-2.jpg?v=2024-06-20T19%3A30%3A27.443Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/667472d98e2e8e00217d2cbe/images/2-2.jpg?v=2024-06-20T19%3A30%3A27.443Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/667472d98e2e8e00217d2cbe/images/2-2.jpg?v=2024-06-20T19%3A30%3A27.443Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Box min-width="10px" min-height="10px" display="flex" margin="0px 0px 35px 0px">
						<Box
							min-width="10px"
							min-height="10px"
							width="55px"
							height="30px"
							background="#dcfc96"
							margin="10px 0px 0px 0px"
						/>
						<Text margin="0px 0px 0 -50px" font="normal 500 28px/1.2 --fontFamily-sans">
							Експерти-техніки
						</Text>
					</Box>
					<Text margin="0px 0px 0 0px" font="normal 300 16px/1.5 --fontFamily-sansTrebuchet" color="#676e75">
						наша команда складається з сертифікованих професіоналів із великим досвідом ремонту ПК, що гарантує, що ваш пристрій у вправних руках.
					</Text>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
				>
					<Image
						src="https://uploads.quarkly.io/667472d98e2e8e00217d2cbe/images/2-3.jpg?v=2024-06-20T19:30:27.471Z"
						display="block"
						margin="0px 0px 45px 0px"
						width="100%"
						height="500px"
						object-fit="cover"
						lg-height="400px"
						md-height="600px"
						sm-height="400px"
						srcSet="https://smartuploads.quarkly.io/667472d98e2e8e00217d2cbe/images/2-3.jpg?v=2024-06-20T19%3A30%3A27.471Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/667472d98e2e8e00217d2cbe/images/2-3.jpg?v=2024-06-20T19%3A30%3A27.471Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/667472d98e2e8e00217d2cbe/images/2-3.jpg?v=2024-06-20T19%3A30%3A27.471Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/667472d98e2e8e00217d2cbe/images/2-3.jpg?v=2024-06-20T19%3A30%3A27.471Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/667472d98e2e8e00217d2cbe/images/2-3.jpg?v=2024-06-20T19%3A30%3A27.471Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/667472d98e2e8e00217d2cbe/images/2-3.jpg?v=2024-06-20T19%3A30%3A27.471Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/667472d98e2e8e00217d2cbe/images/2-3.jpg?v=2024-06-20T19%3A30%3A27.471Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Box min-width="10px" min-height="10px" display="flex" margin="0px 0px 35px 0px">
						<Box
							min-width="10px"
							min-height="10px"
							width="55px"
							height="30px"
							background="#fced96"
							margin="10px 0px 0px 0px"
						/>
						<Text margin="0px 0px 0 -50px" font="normal 500 28px/1.2 --fontFamily-sans">
							Швидка обробка
						</Text>
					</Box>
					<Text margin="0px 0px 0 0px" font="normal 300 16px/1.5 --fontFamily-sansTrebuchet" color="#676e75">
						ми розуміємо терміновість повернення вашого ПК у робочий стан і прагнемо запропонувати найшвидші терміни виконання в галузі.
					</Text>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
					margin="80px 0px 0px 0px"
					md-margin="0 0px 0px 0px"
				>
					<Image
						src="https://uploads.quarkly.io/667472d98e2e8e00217d2cbe/images/2-4.jpg?v=2024-06-20T19:30:27.437Z"
						display="block"
						margin="0px 0px 45px 0px"
						width="100%"
						height="500px"
						object-fit="cover"
						lg-height="400px"
						md-height="600px"
						sm-height="400px"
						srcSet="https://smartuploads.quarkly.io/667472d98e2e8e00217d2cbe/images/2-4.jpg?v=2024-06-20T19%3A30%3A27.437Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/667472d98e2e8e00217d2cbe/images/2-4.jpg?v=2024-06-20T19%3A30%3A27.437Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/667472d98e2e8e00217d2cbe/images/2-4.jpg?v=2024-06-20T19%3A30%3A27.437Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/667472d98e2e8e00217d2cbe/images/2-4.jpg?v=2024-06-20T19%3A30%3A27.437Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/667472d98e2e8e00217d2cbe/images/2-4.jpg?v=2024-06-20T19%3A30%3A27.437Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/667472d98e2e8e00217d2cbe/images/2-4.jpg?v=2024-06-20T19%3A30%3A27.437Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/667472d98e2e8e00217d2cbe/images/2-4.jpg?v=2024-06-20T19%3A30%3A27.437Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Box min-width="10px" min-height="10px" display="flex" margin="0px 0px 35px 0px">
						<Box
							min-width="10px"
							min-height="10px"
							width="55px"
							height="30px"
							background="#c5fcff"
							margin="10px 0px 0px 0px"
						/>
						<Text margin="0px 0px 0 -50px" font="normal 500 28px/1.2 --fontFamily-sans">
							Комплексні послуги
						</Text>
					</Box>
					<Text margin="0px 0px 0 0px" font="normal 300 16px/1.5 --fontFamily-sansTrebuchet" color="#676e75">
						від ремонту та оновлення апаратного забезпечення до встановлення програмного забезпечення та видалення вірусів, ми пропонуємо повний спектр послуг для задоволення всіх потреб вашого ПК.
					</Text>
				</Box>
			</Box>
			<Button
				padding="15px 44px 15px 44px"
				background="--color-darkL1"
				border-color="rgba(236, 255, 0, 0.3)"
				box-shadow="5px 5px 3px 0 rgba(219, 204, 27, 0.69)"
				font="normal 300 16px/1.5 --fontFamily-sansTrebuchet"
				type="link"
				text-decoration-line="initial"
				href="/services"
			>
				Дізнатися більше
			</Button>
		</Section>
		<Section padding="100px 0 100px 0" background="--color-light" quarkly-title="FAQ-10">
			<Box min-width="100px" min-height="100px" display="flex" lg-flex-direction="column">
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 600 36px/1.2 --fontFamily-sans" color="--darkL1">
						Наші Послуги
					</Text>
					<Text
						margin="0px 0px 70px 0px"
						font="normal 300 20px/1.5 --fontFamily-sansHelvetica"
						color="#555a5f"
						sm-margin="0px 0px 50px 0px"
						lg-margin="0px 0px 50px 0px"
					>
						<Em>
							У TechFix ми пропонуємо широкий спектр послуг для кожного аспекту ремонту ПК:
						</Em>
					</Text>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="block"
					grid-template-columns="repeat(2, 1fr)"
					grid-gap="50px 50px"
					md-grid-template-columns="1fr"
					sm-grid-gap="35px 0"
					padding="0px 0px 0px 70px"
					lg-padding="0px 0px 0px 0"
				>
					<Box min-width="100px" min-height="100px" margin="0px 0px 35px 0px">
						<Text margin="0px 0px 12px 0px" font="normal 500 22px/1.2 --fontFamily-sans" color="--darkL1">
							Ремонт апаратного забезпечення: ми вирішуємо будь-які проблеми з апаратним забезпеченням, включаючи заміну екрана, ремонт клавіатури та материнської плати.
							<br />
							<br />
							{"\n"}Усунення несправностей програмного забезпечення: наші експерти можуть діагностувати та вирішити проблеми програмного забезпечення, забезпечуючи безперебійну роботу вашої системи.{"\n"}
							<br />
							<br />
							Видалення вірусів і зловмисних програм: захистіть свій комп’ютер від зловмисних загроз за допомогою наших послуг із ретельного видалення вірусів і зловмисних програм.
							<br />
							<br />
							{"\n"}Відновлення даних: втратили важливі файли? Наші послуги з відновлення даних можуть допомогти відновити ваші цінні дані з пошкоджених або пошкоджених пристроїв зберігання.{"\n"}
							<br />
							<br />
							Оптимізація продуктивності: покращте швидкість і продуктивність свого ПК за допомогою наших послуг оптимізації, включаючи оновлення пам’яті та очищення системи.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Footer>
			<Override slot="link3" />
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="link2" />
			<Override slot="link4" />
		</Components.Footer>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65b7ae60009abe001f027d1c"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});